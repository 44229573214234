














import Vue from 'vue';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  data: () => ({
    selectedPublicationId: undefined as string | undefined,
  }),
  watch: {
    '$router.currentRoute.query.id': {
      immediate: true,
      handler(value: string) {
        this.selectedPublicationId = value;
      },
    },
  },
});
